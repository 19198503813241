import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import roleReducer from './Role/reducer';
import conversationMasterReducer from './Conversation/reducer';

const rootReducer = combineReducers({

    // public
    Layout,
    roleReducer, 
    conversationMasterReducer,


});

export default rootReducer;