import React from 'react';


const handleStatusChange = async (isActive, _id, Page_URL, fetchDetails, successMessage, errorMessage, setAlertMessage) => {
  try {
    const response = await fetch(Page_URL, {
      method: 'PUT',
      body: JSON.stringify({ isActive: isActive ? 1 : 0 }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      fetchDetails();
       setAlertMessage((prevMessage) => ({ ...prevMessage, success: successMessage }));
    } else {
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
    }
  } catch (error) {
    console.error('Error saving data:', error);
    setAlertMessage((prevMessage) => ({ ...prevMessage, error: errorMessage }));
  }
};


const ActiveStatusChange = ({ _id, Page_URL, fetchDetails, message }) => {
  return null;
};
const handleDelete = async (_id, Page_URL, fetchDetails, setAlertMessage) => {
  try {
    await fetch(`${Page_URL}`, {
      method: 'DELETE',
    });
    fetchDetails();
    setAlertMessage((prevMessage) => ({ ...prevMessage, success: 'Deleted Successfully' }));
  } catch (error) {
    console.error('Error deleting data:', error);
    if (error.response && error.response.data) {
      console.log('Response data:', error.response.data);
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: error.response.data.error }));
    } else {
      setAlertMessage((prevMessage) => ({ ...prevMessage, error: 'An error occurred while deleting the data.' }));
    }
  }
};


export { ActiveStatusChange, handleStatusChange, handleDelete };