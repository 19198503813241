import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RevenueAnalytics from "./RevenueAnalytics";
import MiniWidgets from "./MiniWidgets";
import SalesAnalytics from "./SalesAnalytics";
import { useSelector } from "react-redux";
import AdminBarChart from "./AdminBarChart";
import AdminRadioChart from "./AdminRadioChart";
import AdminCalender from "./AdminCalender";
import AdminVectorMap from "./AdminVectorMap";
const Dashboard = () => {
    const userRole = useSelector((state) => state.roleReducer.userRole);
    const userId = useSelector((state) => state.roleReducer.userId);

  const breadcrumbItems = [
    { title: "YDS", link: "/" },
    { title: "Dashboard", link: "/dashboard" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <div className="row">
            <div className="col-12">
              <MiniWidgets />
             
              
            </div>
            <div className="col-7">
            <AdminVectorMap/>
            </div>
            <div className="col-5">
              
              <AdminCalender />
            </div>
           
       
           
           
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
