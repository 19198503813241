import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { AvForm, AvField, AVFormGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import DataTable from 'react-data-table-component';
import "flatpickr/dist/themes/material_blue.css"
import { useHistory } from 'react-router-dom';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import { readExcelFile } from 'react-excel-workbook';
import XLSX from 'xlsx'; // Import the 'xlsx' library


import { ReactToPrint } from 'react-to-print';

function AddressMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenlink, setIsModalOpenlink] = useState(false);
    const [importedData, setImportedData] = useState([]);
    const [selectedRowDescription, setSelectedRowDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [selectedRowlink, setSelectedRowlink] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const [isModalOpenUser, setIsModalOpenUser] = useState(false);
     const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

    const componentRef = useRef(null);

    const handleOpenModal = (row) => {
        setIsModalOpen(true);
    };

    const handleOpenModallink = (row) => {
        setSelectedRowlink(row.link);
        setIsModalOpenlink(true);
    };

    const handleCloseModallink = () => {
        setIsModalOpenlink(false);
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenModalUser = (row) => {
        setSelectedUser(row);
        setIsModalOpenUser(true);
    };
    const handleCloseModalUser = () => {
        setIsModalOpenUser(false);
    };
    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            fullName: '',
            email: '',
            invitation: '',
            rakhi: '',
            patrika: '',
            location: '',
            houseNo: '',
            streetName: '',
            area: '',
            subuarb: '',
            cityMaster: '',
            stateMaster: '',
            countryMaster: '',
            pincode: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        invitation: '',
        rakhi: '',
        patrika: '',
        location: '',
        houseNo: '',
        streetName: '',
        area: '',
        subuarb: '',
        cityMaster: '',
        stateMaster: '',
        countryMaster: '',
        pincode: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const formattedDob = formData.dob
                ? [
                    formData.dob.getDate(), // Get the day component
                    formData.dob.getMonth() + 1, // Get the month component (0-based index)
                    formData.dob.getFullYear(), // Get the year component
                ].join('-')
                : '';

            const requestBody = {
                fullName: formData.fullName,
                phone: formData.phone,
                email: formData.email,
                dob: formattedDob,
                invitation: formData.invitation,
                rakhi: formData.rakhi,
                patrika: formData.patrika,
                location: formData.location,
                houseNo: formData.houseNo,
                streetName: formData.streetName,
                area: formData.area,
                subuarb: formData.subuarb,
                cityMaster: formData.cityMaster,
                stateMaster: formData.stateMaster,
                countryMaster: formData.countryMaster,
                pincode: formData.pincode,

            };
            const response = await fetch(`${BASE_URL}/address`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    categoryName: '',
                });
                toggleAddModal();
                fetchTagsDetails();
                history.push('/address');
                setAlertMessage({ success: 'Tags added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the tags.' });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchTagsDetails();
    }, []);

    const fetchTagsDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/address`);
            const data = await response.json();
            if (response.ok) {
                const dataWithSerialNo = data.data.map((item, index) => ({ ...item, srNo: index + 1 }));
                setUserDetails(dataWithSerialNo);
                
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch tags details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching tags data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
        setSpeakerDetails([...speakerDetails]);
    };

    const handleDeleteClick = (_id) => {
        console.log('Delete button clicked for ID:', _id);
        const Page_Url = `${BASE_URL}/address/${_id}`;
        handleDelete(_id, Page_Url, fetchTagsDetails, setAlertMessage);
    };

    const handleActiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/address/status/${_id}`;
        const isActive = 1;
        const successMessageProp = 'Activated Address Successfully';
        const errorMessageProp = 'Failed to Update Address';
        handleStatusChange(isActive, _id, Page_Url, fetchTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleDeactiveClick = (_id) => {
        const Page_Url = `${BASE_URL}/address/status/${_id}`;
        const isActive = 0;
        const successMessageProp = 'Deactivated Address Successfully';
        const errorMessageProp = 'Failed to Deactivate Address';
        handleStatusChange(isActive, _id, Page_Url, fetchTagsDetails, successMessageProp, errorMessageProp, setAlertMessage);
    };

    const handleAction = (action, row) => {
        if (action.toLowerCase() === 'edit') {
            handleEdit(row);
        } else if (action.toLowerCase() === 'delete') {
            handleDeleteClick(row._id);
        } else if (action.toLowerCase() === 'active') {
            handleActiveClick(row._id);
        } else if (action.toLowerCase() === 'deactive') {
            handleDeactiveClick(row._id);
        }
    };

    const renderActionsDropdown = (row) => (
        <ActionsDropdown
            row={row}
            toggleDropdown={toggleDropdown}
            handleAction={handleAction}
            isActive={row.isActive}
        />
    );

    const filteredData = userDetails.filter((user) => {
        const { _id, fullName, phone, email, dob, countryMaster, cityMaster, stateMaster, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((fullName) && (fullName).toLowerCase().includes(lowercasedSearchText)) ||
            ((phone) && (phone).toLowerCase().includes(lowercasedSearchText)) ||
            (email && email.toLowerCase().includes(lowercasedSearchText)) ||
            (dob && dob.toLowerCase().includes(lowercasedSearchText)) ||
            (countryMaster && countryMaster.toLowerCase().includes(lowercasedSearchText)) ||
            (cityMaster && cityMaster.toLowerCase().includes(lowercasedSearchText)) ||
            (stateMaster && stateMaster.toLowerCase().includes(lowercasedSearchText))

        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: (row) => row.srNo,
            sortable: true,
            width: '70px'
        },
        {
            name: 'Full Name',
            cell: (row) => (
                <div className="clickable-row" style={{cursor:'pointer'}} onClick={() => handleOpenModalUser(row)}>
                    <span>{(row.fullName) + ' '}</span>
                    <i className="fas fa-info" style={{ color: '#999', fontSize: '8px' }}></i>
                </div>
            ),
            sortable: true,
            width: '250px'
        },
      
       
        { name: 'Abroad', selector: (row) => row.location, sortable: true },
        {
            name: 'Address',
            selector: (row) => {
                if (row.houseNo !== null && row.streetName !== null && row.area !== null) {
                    return row.houseNo + " " + row.streetName + " " + row.area;
                } else {
                    return ' ';
                }
            },
            sortable: true,
            width: '200px'
        },
        { name: 'Subuarbs', selector: (row) => row.subuarb, sortable: true },
        { name: 'City', selector: (row) => row.cityMaster, sortable: true },
        { name: 'State', selector: (row) => row.stateMaster, sortable: true },
        { name: 'Country', selector: (row) => row.countryMaster, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true, width: '175px' },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id); // Pass selectedUser._id as argument
            setSelectedUser(null);
            toggleEditModal();
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        let selectedValue = value;
        if (e.target.nodeName === 'SELECT') {
            const selectedOption = Array.from(e.target.selectedOptions).map((option) => option.value);
            selectedValue = selectedOption.length > 0 ? selectedOption : null;
        }

        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: selectedValue,
        }));
    };

    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const formattedDob = selectedUser.dob
                ? [
                    selectedUser.dob.getDate(), // Get the day component
                    selectedUser.dob.getMonth() + 1, // Get the month component (0-based index)
                    selectedUser.dob.getFullYear(), // Get the year component
                ].join('-')
                : '';
            const requestBody = {
                fullName: selectedUser.fullName,
                phone: selectedUser.phone,
                email: selectedUser.email,
                dob: formattedDob,
                invitation: selectedUser.invitation,
                rakhi: selectedUser.rakhi,
                patrika: selectedUser.patrika,
                location: selectedUser.location,
                houseNo: selectedUser.houseNo,
                streetName: selectedUser.streetName,
                area: selectedUser.area,
                subuarb: selectedUser.subuarb,
                cityMaster: selectedUser.cityMaster,
                stateMaster: selectedUser.stateMaster,
                countryMaster: selectedUser.countryMaster,
                pincode: selectedUser.pincode,
                createdDate: selectedUser.createdDate, // Include the createdDate field
            };
            const response = await fetch(`${BASE_URL}/address/${selectedUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            if (response.ok) {
                fetchTagsDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Tags Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the tags.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the tags.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleFileUpload = async (e) => {
        try {
            const uploadedFile = e.target.files[0];
            if (!uploadedFile) return;

            if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || uploadedFile.type === 'application/vnd.ms-excel') {
                const workbookData = await readExcelFile(uploadedFile);
                setImportedData(workbookData);
            } else if (uploadedFile.type === 'text/csv') {
                const csvData = await readCSVFile(uploadedFile);
                setImportedData(csvData);
            } else {
                console.error('Unsupported file format');
            }
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const content = event.target.result;

                    // Process the Excel file content here
                    const workbookData = processExcelContent(content);

                    resolve(workbookData);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };

    // Define a function to process the Excel content
    const processExcelContent = (content) => {
        try {
            // Parse the content using 'xlsx' library
            const workbook = XLSX.read(content, { type: 'binary' });

            // Process the workbook and extract data as needed
            // For example, you can access sheets and data like this:
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            return sheetData; // Return the processed data
        } catch (error) {
            throw error;
        }
    };

    const readCSVFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                const rows = content.split(/\r\n|\n/);
                const data = rows.map(row => row.split(','));
                resolve(data);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const handleSendValues = async (e) => { // Add 'async' keyword here
        e.preventDefault();
        setSubmitting(true);

        try {
            console.log(importedData)
            const headerRow = importedData[0];
            const dataRows = importedData.slice(1);
            const transformedData = dataRows.map((row) => {
                const rowData = headerRow.reduce((acc, column, i) => {
                    if (row[i] !== undefined && column !== undefined) {
                        acc[column.replace(/"/g, '')] = row[i].replace(/"/g, ''); // Remove double quotes
                    }
                    return acc;
                }, {});
                return rowData;
            });
            console.log(transformedData)
            const requestData = {
                importedData: transformedData,
            };


            const response = await fetch(`${BASE_URL}/address/imported-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            if (response.ok) {

                //       history.push('/attendees');
                setAlertMessage({ success: 'Event added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the event.' });
        } finally {
            setSubmitting(false);
        }
    };


    const dummy = [
        { First_Name: 'John', Last_Name: 'Doe', Phone: '123456789', Email: 'john@example.com' },
        { First_Name: 'Jane', Last_Name: 'Smith', Phone: '987154877', Email: 'jane@example.com' },
        { First_Name: 'Alice', Last_Name: 'Johnson', Phone: '5554578454', Email: 'alice@example.com' },
        { First_Name: 'Bob', Last_Name: 'Brown', Phone: '7745789545', Email: 'bob@example.com' },
        { First_Name: 'Eve', Last_Name: 'Evans', Phone: '8884578612', Email: 'eve@example.com' },
    ];


const handlePrint = () => {
  if (filteredData.length > 0) {
    console.log(filteredData)
    // Sort userDetails by countryMaster
    filteredData.sort((a, b) => (a.countryMaster && b.countryMaster) ? a.countryMaster.localeCompare(b.countryMaster) : 0);
 //   userDetails.sort((a, b) => (a.countryMaster > b.countryMaster) ? 1 : -1);

    // Create a new HTML page for printing
    const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.open();

    // Define the number of elements to be printed per page
    const elementsPerPage = 24;

    // Define the margin for the top of each page
    const topMargin = '50px';

    // Keep track of the serial number for each country
    const countryCodes = {};

    for (let i = 0; i < filteredData.length; i += elementsPerPage) {
      // Start a new page with custom margins
      printWindow.document.write(`
        <html>
          <head>
            <style>
              /* Center the title using CSS */
              title {
                text-align: right;
              }
              .page {
                margin-top: ${i === 0 ? topMargin : '0'};
                page-break-before: always;
              }
              .grid-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr); /* 3 columns */
                grid-auto-rows: 110px; /* Fixed row height of 100px (adjust as needed) */
                grid-gap: 20px; /* Spacing between grid items */
              }
              .grid-item {
                padding: 20px 10px 10px 10px; 
                border: none;
                font-size: 11px;
                text-align: left; /* Center-align text within grid items */
                width: 200px; /* Fixed width (adjust as needed) */
                font-family: 'Poppins', sans-serif;
              }
            </style>
          </head>
          <body>
            <title>Yds</title>
      `);

      // Loop through userDetails, group by country, and display data in a grid
      printWindow.document.write(`
        <div class="grid-container page">
      `);

      let serialNumber = 1; // Reset the serial number for each page

      for (let j = i; j < i + elementsPerPage && j < filteredData.length; j++) {
        const user = filteredData[j];
        const countryCode = user.countryMaster ? user.countryMaster.substring(0, 2).toUpperCase() : '';
        if (!countryCodes[countryCode]) {
            countryCodes[countryCode] = 1;
        }

        printWindow.document.write('<div class="grid-item">');
        let prefix = '';

        if (user.invitation === 'yes') {
            prefix = 'INV/';
        } else if (user.patrika === 'yes') {
            prefix = 'P/';
        } else if (user.rakhi === 'yes') {
            prefix = 'Rakh/';
        }

        const address = (user.houseNo || ' ') + ' ' + (user.streetName || ' ') + ' ' + (user.area || ' ') + ' ' +
        (user.cityMaster || ' ') + ' ' + (user.stateMaster || ' ') + ' ' + (user.countryMaster || ' ') + ' ' + (user.pincode || ' ');
    if (address.length > 95) {
        printWindow.document.write(`<div style="font-size:10px">${prefix}${countryCode}${countryCodes[countryCode]}</div>`);
        printWindow.document.write(`<div><strong style="font-size:12px"> ${user.fullName || ' '}</strong></div>`);
        printWindow.document.write(`<div style="font-size:10px">${address}</div>`);
    } else {
        printWindow.document.write(`<div style="font-size:10px">${prefix}${countryCode}${countryCodes[countryCode]}</div>`);
        printWindow.document.write(`<div><strong style="font-size:12px"> ${user.fullName || ' '}</strong></div>`);
        printWindow.document.write(`<div>${address}</div>`);
    }
   
        printWindow.document.write('</div>');

        // Increment the serial number for the country
        countryCodes[countryCode]++;
                serialNumber++;
      }

      // Close the HTML structure for this page
      printWindow.document.write('</div></body></html>');
    }

    printWindow.document.close();

    // Print the new pages
    printWindow.print();
    printWindow.close();
  } else {
    // Handle the case where there is no data
    console.log('No data to print');
  }
};


    useEffect(() => {
        // Trigger the print action after data has been fetched
        if (userDetails.length > 0) {
            componentRef.current && componentRef.current.click();
        }
    }, [userDetails]);

    const formattedDob = formData.dob
        ? format(new Date(formData.dob), 'dd-MM-yyyy')
        : '';
    const isUserSelected = Object.keys(selectedUser).length > 0;
    const isSelectedUser = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Address Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Row>
                                        <Col md="4" className='d-flex justify-content-end'>
                                            <CSVLink data={userDetails} filename='SponsorData' className='btn btn-primary'>Export to Excel</CSVLink>
                                        </Col>
                                        <Col md="8">
                                            <button className='btn btn-primary ' onClick={handleOpenModal}>Import to Excel</button>
                                            {userDetails.length > 0 && (
                                                <Button
                                                    style={{ marginLeft: '20px' }}
                                                    color="primary"
                                                    onClick={handlePrint}
                                                >
                                                    Print
                                                </Button>
                                            )}
                                        </Col>
                                        <Col md="4">

                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add Address</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAdd}>

                        <div className="form-group mb-2">
                            <label htmlFor="fullName">Full Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="fullName"
                                placeholder="Full Name"
                                value={formData.fullName || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Name' },
                                }}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="phone">Phone</label>
                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="phone"
                                        placeholder="Phone"
                                        value={formData.phone || ''}
                                        onChange={handleInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Phone' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <Flatpickr
                                        name="dob"
                                        value={formattedDob || ''}
                                        className="form-control d-block"
                                        placeholder="Date of birth"
                                        options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "d-m-Y", // Change this to "d-m-Y"
                                        }}
                                        onChange={(date) => handleInputChange({ target: { name: "dob", value: date[0] } })}
                                    />


                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-2">
                            <label htmlFor="email">Email</label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email || ''}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your Email' },
                                }}
                            />
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="invitation">
                                        Invitation
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="invitation" value={formData.invitation} onChange={handleInputChange} validate={{ required: { value: true, errorMessage: "Invitation is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Yes" value="Yes" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="No" value="No" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="rakhi">
                                        Rakhi
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="rakhi" onChange={handleInputChange} value={formData.rakhi} validate={{ required: { value: true, errorMessage: "Rakhi is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Yes" value="yes" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="No" value="No" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="patrika">
                                        Patrika
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="patrika" value={formData.patrika} onChange={handleInputChange} validate={{ required: { value: true, errorMessage: "Invitation is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Yes" value="Yes" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="No" value="No" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-2">
                                    <label className="col-lg-12 col-form-label" htmlFor="location">
                                        Location
                                    </label>
                                    <div className="col-lg-10">
                                        <AvRadioGroup name="location" onChange={handleInputChange} value={formData.location} validate={{ required: { value: true, errorMessage: "Rakhi is required" } }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <AvRadio label="Abroad" value="yes" />
                                                </div>
                                                <div className="col-6">
                                                    <AvRadio label="India" value="No" />
                                                </div>
                                            </div>
                                        </AvRadioGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 form-group mb-2">
                                <label htmlFor="houseName">House Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="houseNo"
                                    placeholder="House Name, Plot No, Flat No"
                                    value={formData.houseNo || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your House Name' },
                                    }}
                                />
                            </div>


                            <div className="col-6 form-group mb-2">
                                <label htmlFor="streetName">Street Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="streetName"
                                    placeholder="Street Name"
                                    value={formData.streetName || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Addresss' },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 form-group mb-2">
                                <label htmlFor="area">Area</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="area"
                                    placeholder="area"
                                    value={formData.area || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Area Name' },
                                    }}
                                />
                            </div>


                            <div className="col-6 form-group mb-2">
                                <label htmlFor="subuarb">Subuarb Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="subuarb"
                                    placeholder="Subuarb Name"
                                    value={formData.subuarb || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Addresss' },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-6 form-group mb-2">
                                <label htmlFor="subuarb">City</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="cityMaster"
                                    placeholder="City Name"
                                    value={formData.cityMaster || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your city' },
                                    }}
                                />
                            </div>
                            <div className="col-6 form-group mb-2">
                                <label htmlFor="stateMaster">State</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="stateMaster"
                                    placeholder="area"
                                    value={formData.stateMaster || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your State' },
                                    }}
                                />
                            </div>

                        </div>
                        <div className="row">


                            <div className="col-6 form-group mb-2">
                                <label htmlFor="countryMaster">Country Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="countryMaster"
                                    placeholder="Country Name"
                                    value={formData.countryMaster || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Country' },
                                    }}
                                />
                            </div>
                            <div className="col-6 form-group mb-2">
                                <label htmlFor="area">Pincode</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="pincode"
                                    placeholder="pincode"
                                    value={formData.pincode || ''}
                                    onChange={handleInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Pincode' },
                                    }}
                                />
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit Tags</ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleEditUser}>

                            <div className="form-group mb-2">
                                <label htmlFor="fullName">Full Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="fullName"
                                    placeholder="Full Name"
                                    value={selectedUser.fullName || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Name' },
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="email">Phone</label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="phone"
                                            placeholder="Phone"
                                            value={selectedUser.phone || ''}
                                            onChange={handleEditInputChange}
                                            validate={{
                                                required: { value: true, errorMessage: 'Please enter your Phone' },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label htmlFor="dob">Date of Birth</label>
                                        <Flatpickr
                                            name="dob"
                                            value={formattedDob || ''}
                                            className="form-control d-block"
                                            placeholder="Date of birth"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "d-m-Y", // Change this to "d-m-Y"
                                            }}
                                            onChange={(date) => handleEditInputChange({ target: { name: "dob", value: date[0] } })}
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="email">Email</label>
                                <AvField
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    value={selectedUser.email || ''}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your Email' },
                                    }}
                                />
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="invitation">
                                            Invitation
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="invitation" value={selectedUser.invitation} onChange={handleEditInputChange} validate={{ required: { value: true, errorMessage: "Invitation is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Yes" value="Yes" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="No" value="No" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="rakhi">
                                            Rakhi
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="rakhi" onChange={handleEditInputChange} value={selectedUser.rakhi} validate={{ required: { value: true, errorMessage: "Rakhi is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Yes" value="yes" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="No" value="No" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="patrika">
                                            Patrika
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="patrika" value={selectedUser.patrika} onChange={handleEditInputChange} validate={{ required: { value: true, errorMessage: "Invitation is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Yes" value="Yes" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="No" value="No" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-2">
                                        <label className="col-lg-12 col-form-label" htmlFor="location">
                                            Location
                                        </label>
                                        <div className="col-lg-10">
                                            <AvRadioGroup name="location" onChange={handleEditInputChange} value={selectedUser.location} validate={{ required: { value: true, errorMessage: "Rakhi is required" } }}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <AvRadio label="Abroad" value="yes" />
                                                    </div>
                                                    <div className="col-6">
                                                        <AvRadio label="India" value="No" />
                                                    </div>
                                                </div>
                                            </AvRadioGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="houseName">House Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="houseNo"
                                        placeholder="House Name, Plot No, Flat No"
                                        value={selectedUser.houseNo || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your House Name' },
                                        }}
                                    />
                                </div>


                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="streetName">Street Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="streetName"
                                        placeholder="Street Name"
                                        value={selectedUser.streetName || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Addresss' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="area">Area</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="area"
                                        placeholder="area"
                                        value={selectedUser.area || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Area Name' },
                                        }}
                                    />
                                </div>


                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="subuarb">Subuarb Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="subuarb"
                                        placeholder="Subuarb Name"
                                        value={selectedUser.subuarb || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Addresss' },
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="subuarb">City</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="cityMaster"
                                        placeholder="City Name"
                                        value={selectedUser.cityMaster || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your city' },
                                        }}
                                    />
                                </div>
                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="stateMaster">State</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="stateMaster"
                                        placeholder="area"
                                        value={selectedUser.stateMaster || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your State' },
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="row">


                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="countryMaster">Country Name</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="countryMaster"
                                        placeholder="Country Name"
                                        value={selectedUser.countryMaster || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Country' },
                                        }}
                                    />
                                </div>
                                <div className="col-6 form-group mb-2">
                                    <label htmlFor="area">Pincode</label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="pincode"
                                        placeholder="pincode"
                                        value={selectedUser.pincode || ''}
                                        onChange={handleEditInputChange}
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter your Pincode' },
                                        }}
                                    />
                                </div>
                            </div>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}



            <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>Import Excel</ModalHeader>
                <ModalBody>
                    <div className="form-group mt-4 mb-2">
                        <label htmlFor="data">Upload Excel</label>

                        <input
                            type="file"
                            className="form-control"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleFileUpload}
                            style={{ width: '100%' }} // Set input width to 100%
                        />
                    </div>
                    <div className="form-group mt-4 mb-2">
                        <Card>
                            <CardBody>
                                <label htmlFor="data">Example for reference</label>
                                <div className="table-responsive">
                                    <Table striped className=" mb-0">

                                        <thead>
                                            <tr>
                                                <th>First_Name</th>
                                                <th>Last_Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dummy.map((entry, index) => (
                                                <tr key={index}>
                                                    <td>{entry.First_Name}</td>
                                                    <td>{entry.Last_Name}</td>
                                                    <td>{entry.Phone}</td>
                                                    <td>{entry.Email}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                            </CardBody>
                        </Card>
                    </div>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleSendValues}>
                            Add
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>

            {isSelectedUser && (
                <Modal isOpen={isModalOpenUser} toggle={handleCloseModalUser}>
                    <ModalHeader toggle={handleCloseModalUser}>User Details</ModalHeader>
                    <ModalBody>
                        <div className="form-group  mb-2">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table striped className="mb-0">
                                            <tbody>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <td>{selectedUser.fullName || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{selectedUser.email || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>{selectedUser.phone || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Birth</th>
                                                    <td>{selectedUser.dob || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Invitation</th>
                                                    <td>{selectedUser.invitation || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Pratika</th>
                                                    <td>{selectedUser.patrika || 'N/A'}</td>
                                                </tr>


                                                <tr>
                                                    <th>Street Name</th>
                                                    <td>{(selectedUser.houseNo || '') + ' ' + (selectedUser.streetName || '') + ' ' + (selectedUser.area || '') || 'N/A'}</td>
                                                </tr>


                                                <tr>
                                                    <th>Suburabs</th>
                                                    <td>{selectedUser.subuarb || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>City</th>
                                                    <td>{selectedUser.cityMaster || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>State</th>
                                                    <td>{selectedUser.stateMaster || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Country</th>
                                                    <td>{selectedUser.countryMaster || 'N/A'}</td>
                                                </tr>
                                                <tr>
                                                    <th>PinCode</th>
                                                    <td>{selectedUser.pincode || 'N/A'}</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModalUser}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}

export default AddressMaster;
