import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import ForgetPwd from "../pages/Authentication/ForgetPassword";


// Dashboard
import Dashboard from "../pages/Dashboard/index";


import AddressMaster from "../pages/Address Master/AddressMaster";

import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";
import PasswordMaster from "../pages/Password Master/PasswordMaster";
import UserMaster from "../pages/User Master/UserMaster";
import PrintMaster from "../pages/Address Master/PrintMaster";




const authProtectedRoutes = [
	{ path: "/icons-remix", component: RemixIcons },
	{ path: "/material-design", component: MaterialDesign },
	{ path: "/dripicons", component: DripiIcons },
	{ path: "/font-awesome-5", component: FontAwesome },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/address", component: AddressMaster },
	{ path: "/print", component: PrintMaster },
	{ path: "/user", component: UserMaster },
	];

	const authProtectedUserRoutes = [
		{ path: "/dashboard", component: Dashboard },
		{ path: "/address", component: AddressMaster },
		{ path: "/print", component: PrintMaster },
	
		];
const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },


	
];

export { authProtectedRoutes, authProtectedUserRoutes, publicRoutes };
