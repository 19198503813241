import React, { useState } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { setUserId, setUserRole, setUserName, setO_photo} from '../../store/Role/action';

import { useDispatch } from 'react-redux';
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' }); // Define the alertMessage state here

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    
  const handleSubmit = async (event, values) => {
    event.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.status && data.data.jwtToken) {
        setIsLoggedIn(true);
        const sessionToken = data.data.jwtToken;
        dispatch(setUserRole(data.data.role));
        dispatch(setUserId(data.data.userId));
        dispatch(setUserName(data.data.fullName));
        dispatch(setO_photo(data.data.u_photo));
        const sessionExpiration = new Date().getTime() + 5000 * 60 * 5000;
        sessionStorage.setItem('sessionToken', sessionToken);
        sessionStorage.setItem('sessionExpiration', sessionExpiration);
        history.push('/dashboard');
      } else {
        setAlertMessage({ success: '', error: data.error || 'Invalid credentials' });
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('API Error:', error);

      setIsLoggedIn(false);
      setAlertMessage({ success: '', error: 'Invalid credentials' });
    }
  };

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                    <Col lg={6}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                        <Col lg={6} style={{background:"#ffffff"}}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center mt-6">
                                                    <div>
                                                        <Link to="/" className="">
                                                         <img src={logodark} alt="" height="100" className="auth-logo logo-dark mx-auto" />
                                                          
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Jay Swaminarayan !</h4>
                                                        </div>

                                                <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />

                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField name="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control" id="email" validate={{ email: true, required: true }} placeholder="Enter Email" />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </div>

                                                      

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                        </div>

                                                       
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center" style={{bottom:'0'}}>
                                                    <p> © {new Date().getFullYear()}  CodeLaksh. </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                      
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
