import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {

  changeSidebarType,

} from "../../store/actions";
// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      showPreloader: true, // Set showPreloader to true initially
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0);

    let currentPath = this.capitalizeFirstLetter(this.props.location.pathname);
    currentPath = currentPath.replaceAll("/", " ");
    document.title =  "Yds";

    setTimeout(() => {
      this.setState({ showPreloader: false });
    }, 1350);   }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  render() {
    const sidebarStyle = this.state.isMobile
    ? {
        overflowY: 'auto',
        /* Add any additional styles you want for the scrollbar here */
      }
    : {};
    return (
      <React.Fragment>
        {this.state.showPreloader && (
          <div id="preloader">
            <div id="status">
              <div className="spinner">
                <i className="ri-loader-line spin-icon"></i>
              </div>
            </div>
          </div>
        )}

        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
            style={{ maxHeight: "100%" }}
          />
          <div className="main-content">
            {this.props.children}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};


export default connect(mapStatetoProps, {changeSidebarType})(withRouter(Layout));
