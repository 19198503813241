import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { handleStatusChange, handleDelete } from '../../components/Common/ActiveStatusChange';
import ActionsDropdown from '../../components/Common/DropDown';
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { CSVLink } from 'react-csv';
import Select from "react-select";
function UserMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageModel, setErrorMessageModel] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

    const [selectedRole, setSelectedRole] = useState(null);
    const history = useHistory();
    const toggleAddModal = () => {
        setAddModalOpen(!addModalOpen);
    };

    const handleAddUser = () => {
        setFormData({
            role:'',
            fullName: '',
            phone: '',
            email: '',
            password: '',
        });
        toggleAddModal();
    };

    const [formData, setFormData] = useState({
        role:'',
        fullName: '',
        phone: '',
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleAdd = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setSubmitting(true);
        if (!selectedUser.u_photo) {
            setErrorMessageModel('Please upload a file.');
            return;
        }
        try {
            const form = new FormData();
            form.append('role', formData.role);
            form.append('fullName', formData.fullName);
            form.append('phone', formData.phone);
            form.append('email', formData.email);
            form.append('password', formData.password);
            form.append('u_photo', selectedUser.u_photo);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            if (!allowedTypes.includes(selectedUser.u_photo.type)) {
                setErrorMessage('Invalid file type. Please upload a PNG, JPG, or JPEG file.');
                return;
            }
            if (selectedUser.u_photo.size > maxFileSize) {
                setErrorMessage('File size limit exceeded. Please upload a file up to 2MB.');
                return;
            }
            const response = await fetch(`${BASE_URL}/register`, {
                method: 'POST',
                body: form,
            });
            const data = await response.json();
            if (response.ok) {
                setUserDetails([...userDetails, data]);
                setFormData({
                    role: '',
                    fullName: '',
                    phone: '',
                    email: '',
                    password: ''
                });
                toggleAddModal();
                fetchUserDetails();
                history.push('/user');
                setAlertMessage({ success: 'Organiser added successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while adding the organiser.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while adding the organiser.' });
        } finally {
            setSubmitting(false);
        }
    };

    const handleu_photoChange = (event) => {
        const file = event.target.files[0];
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB
        setSelectedUser((prevSelectedUser) => ({
            ...prevSelectedUser,
            u_photo: file,
            imageName: file ? file.name : '',
        }));
        if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
            console.log('Valid file:', file);
            setErrorMessage(''); 
        } else {
            console.log('Invalid file');
            setErrorMessage('Invalid file. Please upload a PNG, JPG, or JPEG file within 2MB.'); 
        }
    };


    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/login`);
            const data = await response.json();
            if (response.ok) {
                setUserDetails(data.data);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch organiser details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching organiser data' });
            console.error(error);
        }
    };

    const toggleDropdown = (row) => {
        row.dropdownOpen = !row.dropdownOpen;
        setDropdownOpen(!dropdownOpen);
        setUserDetails([...userDetails]);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        toggleEditModal();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const handleDeleteClick = (_id) => {
        const Page_Url = `${BASE_URL}/login/${_id}`;
         handleDelete(_id, Page_Url, fetchUserDetails, setAlertMessage);
     };
 
     const handleActiveClick = (_id) => {
         const Page_Url = `${BASE_URL}/login/status/${_id}`;
         const isActive = 1;
         const successMessageProp = 'Activated Organiser Successfully';
         const errorMessageProp = 'Failed to Organiser Agenda';
         handleStatusChange(isActive, _id, Page_Url, fetchUserDetails, successMessageProp, errorMessageProp, setAlertMessage);
     };
 
     const handleDeactiveClick = (_id) => {
         const Page_Url = `${BASE_URL}/login/status/${_id}`;
         const isActive = 0;
         const successMessageProp = 'Deactivated Organiser Successfully';
         const errorMessageProp = 'Failed to Deactivate Organiser';
         handleStatusChange(isActive, _id, Page_Url, fetchUserDetails, successMessageProp, errorMessageProp, setAlertMessage);
     };
 
     const handleAction = (action, row) => {
         if (action.toLowerCase() === 'edit') {
             handleEdit(row);
         } else if (action.toLowerCase() === 'delete') {
             handleDeleteClick(row._id);
         } else if (action.toLowerCase() === 'active') {
             handleActiveClick(row._id);
         } else if (action.toLowerCase() === 'deactive') {
             handleDeactiveClick(row._id);
         }
     };
 
     const renderActionsDropdown = (row) => (
         <ActionsDropdown
             row={row}
             toggleDropdown={toggleDropdown}
             handleAction={handleAction}
             isActive={row.isActive}
         />
     );
    const filteredData = userDetails.filter((user) => {
        const { _id, fullName, phone, email } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((fullName) && (fullName).toLowerCase().includes(lowercasedSearchText)) ||
            (phone && phone.toLowerCase().includes(lowercasedSearchText)) ||
            (email && email.toLowerCase().includes(lowercasedSearchText))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: () => serialNumber++,
            sortable: true, width: '50px'
        },
        { name: 'Name', selector: (row) => row.fullName, sortable: true, },
        { name: 'Email', selector: (row) => row.email, sortable: true, },
        { name: 'Phone', selector: (row) => row.phone, sortable: true },
        {
            name: 'Profile Picture',
            cell: (row) => {
                const imageUrl = row.u_photo;
                const imageName = imageUrl ? imageUrl.split('/').pop().split('_')[0] : '';
                return (
                    <div>
                        {imageUrl && (
                            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                                <img src={imageUrl} alt="Profile Picture" style={{ width: '50px', margin: '5px' }} />
                            </a>
                        )}
                        <p style={{ fontSize: '12px', marginTop: '5px', textAlign: 'center' }}>
                            {imageName}
                        </p>
                    </div>
                );
            },
            sortable: true,
        },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
            width: '100px'
        },
        {
            name: 'Action',
            cell: (row) => renderActionsDropdown(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]


    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };


    const handleEditUser = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const form = new FormData();
            form.append('role', selectedUser.role);
          
            form.append('fullName', selectedUser.fullName);
            form.append('phone', selectedUser.phone);
           
            form.append('email', selectedUser.email);
             form.append('password', selectedUser.password);
            form.append('u_photo', selectedUser.u_photo);
            console.log('selectedUser:', selectedUser);
            console.log('form:', form);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            const maxFileSize = 2 * 1024 * 1024; // 2MB

            console.log(form)
            if (!selectedUser.u_photo || !allowedTypes.includes(selectedUser.u_photo.type) || selectedUser.u_photo.size > maxFileSize) {
                console.log('Invalid file');
                return;
            }
            const response = await fetch(`${BASE_URL}/login/${selectedUser._id}`, {
                method: 'PUT',
                body: form,
            });
            const responseData = await response.text();
            const data = JSON.parse(responseData);
            if (response.ok) {
                fetchUserDetails();
                toggleEditModal();
                setAlertMessage({ success: 'Event Edited successfully!', error: '' });
            } else {
                setAlertMessage({ success: '', error: 'An error occurred while edit the event.' });
            }
        } catch (error) {
            console.log(error);
            setAlertMessage({ success: '', error: 'An error occurred while editing the event.' });
        } finally {
            setSubmitting(false);
        }
    };


    const handleDeleteUser = async () => {
        if (selectedUser) {
            await handleDelete(selectedUser._id);
            setSelectedUser(null);
            toggleEditModal();
        }
    };

  

    const roleOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'user', label: 'User' },
    ];

    // Function to handle the change in Select value
    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption);
    };


    const isUserSelected = Object.keys(selectedUser).length > 0;

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">User Details</CardTitle>
                        </CardHeader>
                        <CardBody className='mb-4'>
                            <Row>
                                <Col md="8" className="d-flex justify-content-start mb-3">
                                    <CSVLink data={userDetails} filename='OrganiserData' className='btn btn-primary'>Export to Excel</CSVLink>
                                </Col>
                                <Col md="4" className="d-flex justify-content-end mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ borderRadius: '20px', marginRight: '10px' }}
                                    />
                                    <Button color="primary" onClick={handleAddUser}>
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                            <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                            <DataTable className='table-nowrap custom-data-table' columns={columns} data={filteredData} pagination highlightOnHover noHeader responsive keyField="_id" />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add User</ModalHeader>
                <ModalBody>
                <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            
                    <AvForm onValidSubmit={handleAdd}>
                    <div className="form-group mb-2">
        <label htmlFor="role">Role</label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="role"
          placeholder="Select Role"
          value={formData.role}
          options={[
            { value: 'admin', label: 'Admin' },
            { value: 'user', label: 'User' },
          ]}
          onChange={(selectedOption) => setFormData({ ...formData, role: selectedOption ? selectedOption.value : '' })}
          isClearable={true}
          formatOptionLabel={({ label }) => <div>{label}</div>} // Customize the label appearance
        />
      </div>

                        <div className="form-group mb-2">
                            <label htmlFor="fullName">Full Name</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="fullName"
                                placeholder="Name"
                                value={formData.fullName}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your first name' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="phone">Phone</label>
                            <AvField
                                type="text"
                                className="form-control"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your phone number' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="email">Email</label>
                            <AvField
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your email address' },
                                    email: { errorMessage: 'Invalid email address' },
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="password">Password</label>
                            <AvField
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                validate={{
                                    required: { value: true, errorMessage: 'Please enter your password' },

                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <div className="prev-image-container">
                                        <img
                                            src="static/media/avatar-4.jpg"
                                            style={{ width: '50px', height: '70px' }}
                                            alt="Profile"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-9 mb-2">
                                    <label htmlFor="u_photo" className="custom-file-label">
                                        Profile Picture
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="u_photo"
                                            name="u_photo"
                                            onChange={handleu_photoChange}
                                            style={{ width: '100%' }}

                                            required={formSubmitted && !selectedUser.u_photo}
                                        />
                                        {formSubmitted && !selectedUser.u_photo && (
                                            <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                        )}
                                    </label>
                                    {selectedUser.u_photo && (
                                        <div className="text-success">File uploaded successfully.</div>
                                    )}
                                    <small className="form-text text-muted mt-2">
                                        Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                        image icon size: 300x300px.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleAddModal}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={submitting}>
                                {submitting ? "Adding..." : "Add"}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            {isUserSelected && (
                <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
                    <ModalHeader toggle={toggleEditModal}>Edit User</ModalHeader>
                    <ModalBody>
                    <SetAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                         
                        <AvForm onValidSubmit={handleEditUser}>
                        <div className="form-group mb-2">
                            <label htmlFor="role">Role</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="role"
                                placeholder="Select Role"
                                value={selectedUser.role}
                                options={[
                                    { value: 'admin', label: 'Admin' },
                                    { value: 'user', label: 'User' },
                                ]}
                                onChange={(selectedOption) => setFormData({ ...formData, role: selectedOption ? selectedOption.value : '' })}
                                isClearable={true}
                            />
                        </div>
                            <div className="form-group mb-2">
                                <label htmlFor="fullName">Name</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="fullName"
                                    placeholder="Name"
                                    value={selectedUser.fullName}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your first name' },
                                    }}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="phone">Phone</label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    placeholder="Phone"
                                    value={selectedUser.phone}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your phone number' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email</label>
                                <AvField
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    value={selectedUser.email}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your email address' },
                                        email: { errorMessage: 'Invalid email address' },
                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="password">Password</label>
                                <AvField
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="password"
                                    value={selectedUser.password}
                                    onChange={handleEditInputChange}
                                    validate={{
                                        required: { value: true, errorMessage: 'Please enter your password' },

                                    }}
                                />
                            </div>
                            <div className="form-group mb-2">
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-center">
                                        <div className="prev-image-container">
                                            <img
                                                src="static/media/avatar-4.jpg"
                                                style={{ width: '50px', height: '70px' }}
                                                alt="Profile"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9 mb-2">
                                        <label htmlFor="u_photo" className="custom-file-label">
                                            Profile Picture
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="u_photo"
                                                name="u_photo"
                                                onChange={handleu_photoChange}
                                                style={{ width: '100%' }}

                                                required={formSubmitted && !selectedUser.u_photo}
                                            />
                                            {formSubmitted && !selectedUser.u_photo && (
                                                <div className="text-danger">{errorMessageModel || 'Please upload a file.'}</div>
                                            )}
                                        </label>
                                        {selectedUser.u_photo && (
                                            <div className="text-success">File uploaded successfully.</div>
                                        )}
                                        <small className="form-text text-muted mt-2">
                                            Allowed file types: PNG, JPG, JPEG. File ratio: 1:1. File size limit: 2MB. Dummy
                                            image icon size: 300x300px.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <ModalFooter>
                                <Button color="primary" type="submit" disabled={submitting}>
                                    {submitting ? "Editting..." : "Edit"}
                                </Button>
                                <Button color="secondary" onClick={toggleEditModal}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            )}

        </div>
    );
}

export default UserMaster;
