import { SET_USER_ROLE, SET_USER_ID,  SET_USER_NAME, SET_O_PHOTO} from "./actionTypes";


export const setUserRole = (role) => ({
    type: SET_USER_ROLE,
    payload: role,
  });

  
export const setUserId = (_id) => ({
    type: SET_USER_ID,
    payload: _id,
  });

  
export const setUserName = (fullName) => ({
  type: SET_USER_NAME,
  payload: fullName,
});

export const setO_photo = (o_photo) => ({
  type: SET_O_PHOTO,
  payload: o_photo,
});