import { SET_CONVERSATION_MASTER , SET_ORGANISER_MASTER} from "./actionTypes";

const initialState = {
    conversationMaster: null,
    organiserMaster:null
  };

  const roleReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_CONVERSATION_MASTER:
        return {
          ...state,
          conversationMaster: action.payload,
        };
        case SET_ORGANISER_MASTER:
          return {
            ...state,
            organiserMaster: action.payload,
          };
  
       
      default:
        return state;
    }
  };
  
  export default roleReducer;