import React, { useEffect, useState } from 'react';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.css';
import { BASE_URL } from '../../components/Common/AppConfig';
import { Row, Card, CardBody } from 'reactstrap';

const AdminVectorMap = () => {
  const [userData, setUserData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });

  useEffect(() => {
    fetchEventCount();
  }, []);

  const fetchEventCount = async () => {
    try {
      const response = await fetch(`${BASE_URL}/bar-chart-admin`);
      const data = await response.json();

      if (response.ok) {
        setUserData(data.data);
      } else {
        setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
      }
    } catch (error) {
      setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
      console.error(error);
    }
  };

  const mapData = {};
  userData.forEach((item) => {
    mapData[item._id] = item.countryCount;
  });

  return (
<div>
<Card>
        <CardBody>
          <Row >
           <VectorMap
              map="world_mill"
              backgroundColor="transparent"
              containerStyle={{
                width: '100%',
                height: '480px',
                
                 // Adjust the height as per your requirement
              }}
              regionStyle={{
                initial: {
                  fill: '#e4e4e4', // Set the initial color for countries without user count data
                  "fill-opacity": 1,
                  stroke: "#74788d",
                  "stroke-width": 0.5,
                  "stroke-opacity": 1,
                  
                },
                hover: {
                  "fill-opacity": 0.8,
                  fill:'#d43e01',
              
                  cursor: 'pointer',
                  
                },
                selected: {
                  fill: '#2938bc' // Set the color for the clicked country
                },
                selectedHover: {
                    cursor:'pointer'
                }
              }}
              series={{
                regions: [
                  {
                    values: mapData,
                    scale: {
                      '#e4e4e4': '#e4e4e4', // Set the color for countries without data to grey
                      '#ffA500': '#ffA500', // Set the color for countries with data to orange
                      '#ff0000': '#ff0000' // Set the color for countries with data to red
                    },
                    attribute: 'fill'
                  }
                ]
              }}
              onRegionTipShow={(e, el, code) => {
                const countryData = userData.find((item) => item._id.slice(0, 2).toUpperCase() === code);
                console.log(code);
                if (countryData) {
                  el.html(el.html() + ' - Baktos: ' + countryData.countryCount);
                }
              }}
            />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminVectorMap;
