import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody } from "reactstrap";
import { BASE_URL } from '../../components/Common/AppConfig';
import SetAlert from '../../components/Common/SetAlert';
import { useSelector } from 'react-redux';


const MiniWidgets = () => {
    const [addressCount, setAddressCount] = useState('');
    const [rakhiCount, setRakhiCount] = useState('');
    const [patrikaCount, setPatrikaCount] = useState('');
    const [invitationCount, setInvitationCount] = useState('');
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });


    useEffect(() => {
        fetchAddressCount();
        fetchRakhiCount();
        fetchPatrikaCount();
        fetchInvitationCount();

    })


    const fetchAddressCount = async () => {
        try {
            const response = await fetch(`${BASE_URL}/address-total`);
            const data = await response.json();

            if (response.ok) {
              
                    setAddressCount(data.data);
                   
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    const fetchRakhiCount = async () => {
        try {
            const response = await fetch(`${BASE_URL}/rakhi-total`);
            const data = await response.json();
            if (response.ok) {
                setRakhiCount(data.data);
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };



    const fetchPatrikaCount = async () => {
        try {
            const response = await fetch(`${BASE_URL}/patrika-total`);
            const data = await response.json();

            if (response.ok) {
                setPatrikaCount(data.data);
                } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };



    const fetchInvitationCount = async () => {
        try {
            const response = await fetch(`${BASE_URL}/invitation-total`);
            const data = await response.json();

            if (response.ok) {
                
                    setInvitationCount(data.data);
                
            } else {
                setAlertMessage({ success: '', error: 'Failed to fetch event details.' });
            }
        } catch (error) {
            setAlertMessage({ success: '', error: 'An error occurred while fetching event data' });
            console.error(error);
        }
    };

    

    return (
        <React.Fragment>
            <div className="row">

                <div className="col-12 col-sm-6 col-lg-3">
                    <Card>

                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Total Bhaktos</p>
                                    <h4 className="mb-0">
                                        {addressCount} 
                                    
                                        </h4>
                                </div>
                                <div className="text-primary">
                                    <i className=" ri-service-fill" style={{ fontSize: '40px' }}></i>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody className="border-top py-3">
                            <div className="text-truncate">
                                <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up">12 </i></span>
                                <span className="text-muted ms-2">Compared with last month</span>
                            </div>
                        </CardBody>
                    </Card>
                </div>
              
  
               
                    <div className="col-12 col-sm-6 col-lg-3">
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Total Rakhi</p>
                                    <h4 className="mb-0">
                                    {rakhiCount}
                                        </h4>
                                </div>
                                <div className="text-primary">
                                    <i className="  ri-alarm-fill" style={{ fontSize: '40px' }}></i>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody className="border-top py-3">
                            <div className="text-truncate">
                                <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up">12 </i></span>
                                <span className="text-muted ms-2">Compared with last month</span>
                            </div>
                        </CardBody>
                    </Card>
                </div>
              
                
                <div className="col-12 col-sm-6 col-lg-3">
                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Total Patrika</p>
                                    <h4 className="mb-0">
                                       
                                        {patrikaCount}
                                        </h4>
                                </div>
                                <div className="text-primary">
                                    <i className="ri-user-voice-fill" style={{ fontSize: '40px' }}></i>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody className="border-top py-3">
                            <div className="text-truncate">
                                <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up">12 </i></span>
                                <span className="text-muted ms-2">Compared with last month</span>
                            </div>
                        </CardBody>
                    </Card>
                </div>
               
                    <div className="col-12 col-sm-6 col-lg-3">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate font-size-14 mb-2">Total Invitation</p>
                                        <h4 className="mb-0">
                                          {invitationCount}
                                            </h4>
                                    </div>
                                    <div className="text-primary">
                                        <i className="ri-team-fill" style={{ fontSize: '40px' }}></i> 
                                    </div>
                                </div>
                            </CardBody>


                            <CardBody className="border-top py-3">
                                <div className="text-truncate">
                                    <span className="badge badge-soft-success font-size-11 me-1"><i className="mdi mdi-menu-up">12 </i></span>

                                    <span className="text-muted ms-2">Compared with last month</span>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
            

            </div>

        </React.Fragment>
    );
};

export default MiniWidgets;

