import React, { useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from 'reactstrap';
import { BASE_URL } from '../../components/Common/AppConfig';
import DataTable from 'react-data-table-component';
import ReactToPrint from 'react-to-print';


function PrintMaster() {
    const [userDetails, setUserDetails] = useState([]);
    const [searchText, setSearchText] = useState('');
    const componentRef = useRef(null);
    const [printAdditionalData, setPrintAdditionalData] = useState(false); // State to control visibility
    
    useEffect(() => {
        fetchTagsDetails();
    }, []);

    useEffect(() => {
        // Trigger the print action after data has been fetched
        if (userDetails.length > 0) {
            componentRef.current && componentRef.current.click();
        }
    }, [userDetails]);
    const fetchTagsDetails = async () => {
        try {
            const response = await fetch(`${BASE_URL}/address`);
            const data = await response.json();
            if (response.ok) {
                setUserDetails(data.data);
            } else {
            }
        } catch (error) {
            console.error(error);
        }
    };

    const filteredData = userDetails.filter((user) => {
        const { _id, title, eventDate, endTime, startTime, fname, lname } = user;
        const lowercasedSearchText = searchText.toLowerCase();

        return (
            (_id && _id.toString().includes(lowercasedSearchText)) ||
            ((title) && (title).toLowerCase().includes(lowercasedSearchText)) ||
            ((fname && lname) && (fname && lname).toLowerCase().includes(lowercasedSearchText)) ||
            (eventDate && eventDate.toLowerCase().includes(lowercasedSearchText)) ||
            (startTime && startTime.toLowerCase().includes(lowercasedSearchText)) ||
            (endTime && endTime.toLowerCase().includes(lowercasedSearchText))
        );
    });

    let serialNumber = 1;
    const columns = [
        {
            name: '#',
            cell: () => serialNumber++,
            sortable: true,
            width: '50px'
        },
        { name: 'Full Name', selector: (row) => row.fullName, sortable: true },
        { name: 'Phone', selector: (row) => row.phone, sortable: true, width: '150px' },
        { name: 'email', selector: (row) => row.email, sortable: true, width: '200px' },
        { name: 'dob', selector: (row) => row.dob, sortable: true, width: '125px' },
        { name: 'Invitation', selector: (row) => row.invitation, sortable: true },
        { name: 'Patrika', selector: (row) => row.patrika, sortable: true },
        { name: 'Rakhi', selector: (row) => row.rakhi, sortable: true },
        { name: 'Abroad', selector: (row) => row.location, sortable: true },
        { name: 'Address', selector: (row) => row.houseNo + " " + row.streetName + " " + row.area+" " + row.cityMaster+ " " + row.stateMaster+ " "+ row.countryMaster +" ",sortable: true, width: '200px' },
        { name: 'Subuarbs', selector: (row) => row.subuarb, sortable: true },
        { name: 'City', selector: (row) => row.cityMaster, sortable: true },
        { name: 'State', selector: (row) => row.stateMaster, sortable: true },
        { name: 'Country', selector: (row) => row.countryMaster, sortable: true },
        { name: 'Created Date', selector: (row) => row.createdDate || '', sortable: true, width: '175px' },
        {
            name: 'Active',
            cell: (row) => (
                <span style={{ color: row.isActive === 1 ? 'green' : 'red' }}>
                    {row.isActive === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
            sortable: true,
        },

    ];
    const additionalData = (
        <div>
            {userDetails.map((user) => (
                <div key={user._id}>
                    <p>Full Name: {user.fullName}</p>
                    <p>Email: {user.email}</p>
                </div>
            ))}
        </div>
    );
    const handlePrint = () => {
        if (userDetails.length > 0) {
            // Create a new HTML page for printing
            const printWindow = window.open('', '', 'width=600,height=600');
            printWindow.document.open();
    
            // Define the number of elements to be printed per page
            const elementsPerPage = 24;
    
            for (let i = 0; i < userDetails.length; i += elementsPerPage) {
                // Start a new page with custom margins
                printWindow.document.write(`
                <html>
                <head>
                    <style>
                        /* Center the title using CSS */
                        title {
                            text-align: center;
                        }
                      
                        .grid-container {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr); /* 3 columns */
                            grid-auto-rows: 100px; /* Fixed row height of 100px (adjust as needed) */
                            grid-gap: 20px; /* Spacing between grid items */
                        }
                        .grid-item {
                            padding: 10px;
                            border: 1px solid #ccc;
                            font-size: 12px;
                            text-align: center; /* Center-align text within grid items */
                            width: 200px; /* Fixed width (adjust as needed) */
                        }
                    </style>
                </head>
                <body>
                    <title>Yds</title>
                `);
    
                // Loop through userDetails and display data in a grid
                printWindow.document.write('<div class="grid-container">');
                for (let j = i; j < i + elementsPerPage && j < userDetails.length; j++) {
                    const user = userDetails[j];
                    printWindow.document.write('<div class="grid-item">');
                    printWindow.document.write('<div>' + (user.fullName || '') + '</div>');
                    console.log('heko');
                    printWindow.document.write('<div>' + (user.houseNo || '') );
                    printWindow.document.write((user.streetName && user.area ? user.streetName + ' ' + user.area : '')+
                   (user.cityMaster && user.StateMaster ? user.cityMaster + ' ' + user.StateMaster : '') +
                   (user.CountryMaster || '')  + '</div>');
                   console.log(user.cityMaster);
                    // Add more data fields here in the desired format
                    printWindow.document.write('</div>');
                }
    
                // Close the HTML structure for this page
                printWindow.document.write(`
                    </div>
                    </body>
                    </html>
                `);
    
                // Add a page break if there are more elements to print
                if (i + elementsPerPage < userDetails.length) {
                    printWindow.document.write('<div style="page-break-after: always;"></div>');
                }
            }
    
            printWindow.document.close();
    
            // Print the new pages
            printWindow.print();
            printWindow.close();
        } else {
            // Handle the case where there is no data
            console.log('No data to print');
        }
    };
    
    
    

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Address Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Row>
                                        <Col md="4">
                                            {userDetails.length > 0 && (
                                                <Button
                                                    color="primary"
                                                    onClick={handlePrint}
                                                >
                                                    Print
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <DataTable
                                className='table-nowrap custom-data-table'
                                columns={columns}
                                data={filteredData}
                                pagination
                                highlightOnHover
                                noHeader
                                responsive
                                keyField="_id"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
                               

export default PrintMaster;


