import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
const SidebarContent = ({ t, location }) => {
    const [isCondensed, setIsCondensed] = useState(window.innerWidth <= 767);
    const userRole = useSelector((state) => state.roleReducer.userRole);



    useEffect(() => {

        initMenu();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (location && location.pathname) {
            const matchingMenuItem = findMatchingMenuItem(location.pathname);
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
    }, [location]);

    const handleResize = () => {
        setIsCondensed(window.innerWidth <= 767);
    };

    const initMenu = () => {
        new MetisMenu("#side-menu");
    };

    const findMatchingMenuItem = (pathname) => {
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (pathname === items[i].pathname) {
                return items[i];
            }
        }
        return null;
    };

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active");
                    parent3.childNodes[0].classList.add("mm-active");
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
        }
    };

    const sidebarMenuStyle = isCondensed
        ? {
            marginTop: "70px", // Add margin from the top if the sidebar is in condensed view
        }
        : {};

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }}>

                <div id="sidebar-menu" style={sidebarMenuStyle}>
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{t('')}</li>
                        {userRole === "admin" ? (
                            <>
                                <li>
                                    <Link to="/dashboard" className="waves-effect">
                                        <i className="ri-dashboard-line"></i>
                                        {!isCondensed && <span className="ms-1">{t('Dashboard')}</span>}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/user" className=" waves-effect">
                                        <i className=" fas fa-user"></i>

                                        {!isCondensed && <span className="ms-1">{t('User Master')}</span>}
                                    </Link>
                                </li>
                              
                                <li>
                                    <Link to="/address" className=" waves-effect">
                                        <i className="ri-home-4-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Address Master')}</span>}
                                    </Link>

                                </li>
                                {/* <li>
                                    <Link to="/print" className=" waves-effect">
                                        <i className="ri-home-4-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Address Master')}</span>}
                                    </Link>
                                </li> */}

                             </>
                        ) : (

                            <>
                                <li>
                                    <Link to="/dashboard" className="waves-effect">
                                        <i className="ri-dashboard-line"></i>
                                        {!isCondensed && <span className="ms-1">{t('Dashboard')}</span>}
                                    </Link>
                                </li>
                               
                                <li>
                                    <Link to="/address" className=" waves-effect">
                                        <i className="ri-home-4-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Address Master')}</span>}
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/print" className=" waves-effect">
                                        <i className="ri-home-4-fill"></i>

                                        {!isCondensed && <span className="ms-1">{t('Address Master')}</span>}
                                    </Link>
                                </li> */}
                            </>


                        )}

                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
}




export default withRouter(withNamespaces()(SidebarContent));