import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "@fullcalendar/bootstrap/main.css";

function AdminCalendar() {

    const eventColor = "#d43e01";
  return (
    <div>
      <Card>
        <CardBody>
          <Row style={{   height: '480px', }}>
            <FullCalendar
              plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
              slotDuration={"00:15:00"}
              handleWindowResize={true}
              themeSystem="bootstrap"
              editable={true}
              droppable={true}
              selectable={true}
              eventColor={eventColor}
            />
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default AdminCalendar;
